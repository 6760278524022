$myBlack: #04101A;
$myBlackSecondary: #363F47;

html {
  font-size: 100%;
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-evenly;
}

.Page {
  display: grid;
  height: 95vh;
  grid-template-rows: 7% 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "nav nav nav nav"
    "bigLukas bigLukas head head"
    "desc desc head head"
    "thanks bigVisk bigVisk bigVisk";
  padding: 0px;
  margin-bottom: 50px;
}

.NavBar {
  grid-area: nav;
  display: flex;
  height: 100%;

}

.Logo {
  width: 10vw;
  padding: 0%;
  margin: 20px;
  position: fixed;
  order: 1;
}

.Big-Lukas {
  grid-area: bigLukas;
  font-size: 15vw;
  font-family: futura-pt-bold, sans-serif;
  font-weight: 700;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  height: 30vh;
  width: 100%;
  text-align: left;
  padding: 0px;
  margin: 0px;
}
.Blurb {
  display: flex;
  flex-direction: column;
  grid-area: desc;
  margin: 0px;
  padding-left: 15px;
  place-self: center;
}

.Title {
  font-size: 4vw;
  color: $myBlack;
  font-family: futura-pt, sans-serif;
  font-weight: 800;
  padding: 0px;
  margin: 0px;
}

.Subtitle {
  padding: 0px;
  margin: 0px;
  font-size: 1.75vw;
  font-family: futura-pt, sans-serif;
  font-weight: 700;
  color: #D26463;
}

.Description {
  font-size: 1.25vw;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  color: $myBlackSecondary;
  padding: 0px;
  text-indent: 5%;
}

.Social-Icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

}

#spotify {
  margin: 5px;
  color: #3C8D8B;
}

#instagram {
  margin: 5px;
  color:  #D26463;
}

#linkedin {
  margin: 5px;
  color: #3F7EBF;
}

.Self {
  grid-area: head;
  width: 60%;
  padding: 0px;
  margin: 0px;
  place-self: center;
}

.Big-Viskanta {
  grid-area: bigVisk;
  font-size: 15vw;
  font-family: futura-pt-bold, sans-serif;
  font-weight: 700;
  color: $myBlack;
  height: 30vh;
  text-align: right;
  order: 2;
  padding: 0px;
  margin: 0px;


}

.Thanks-For {
  grid-area: thanks;
  animation: spin 8s infinite linear;
  align-self: start;
  justify-self: center;
  width: 15vw;
  object-fit: cover;
  padding: 0px;
  margin: 0px;
  order: 2;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Content {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    height: 100vh;
    width: 95vw;
    margin-top: 5vh;
    column-gap: 15px;
    padding: 15px;
  }

.Item1 {
  grid-row: 1/2;
  grid-column: 1/2;
  place-self: center;
}

.Item2 {
  grid-row: 1/2;
  grid-column: 2/3;
  place-self: center;
}

.Item3 {
  grid-row: 1/2;
  grid-column: 3/4;
  place-self: center;
}

.Item4 {
  grid-row: 1/2;
  grid-column: 4/5;
  place-self: center;
}

.Item5 {
  grid-row: 1/2;
  grid-column: 5/6;
  place-self: center;
}

.Item6 {
  grid-row: 1/2;
  grid-column: 6/7;
  place-self: center;
}

.Item7 {
  grid-row: 2/3;
  grid-column: 1/2;
  place-self: center;
}

.Item8 {
  grid-row: 2/3;
  grid-column: 2/3;
  place-self: center;
}

.DesignTitle {
  grid-row: 2/3;
  grid-column: 3/5;
  text-align: center;
  place-self: center;
  font-family: futura-pt-bold, sans-serif;
  font-size: 7vw;

}

.Item9 {
  grid-row: 2/3;
  grid-column: 5/6;
  place-self: center;
}

.Item10 {
  grid-row: 2/3;
  grid-column: 6/7;
  place-self: center;
}

.Item11 {
  grid-row: 3/4;
  grid-column: 1/2;
  place-self: center;
}

.Item12 {
  grid-row: 3/4;
  grid-column: 2/3;
  place-self: center;
}

.Item13 {
  grid-row: 3/4;
  grid-column: 3/4;
  place-self: center;
}

.Item14 {
  grid-row: 3/4;
  grid-column: 4/5;
  place-self: center;
}

.Item15 {
  grid-row: 3/4;
  grid-column: 5/6;
  place-self: center;
}

.Item16 {
  grid-row: 3/4;
  grid-column: 6/7;
  place-self: center;
}



.Playlist-Container {
  height: 35vw;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.Conetent2 {
  display: none;
}

.UITitle {
  display: none;
}

.ele2 {
  display: none;
}

.ele3 {
  display: none;
}

.ele4 {
  display: none;
}

.ele5 {
  display: none;
}

.ele6 {
  display: none;
}

.Footer {
  width: 100vw;
  background-color: #3F7EBF;
  color: white;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  padding: 15px;
}

.SocialIcons{
  grid-row: 1/2;
  grid-column: 1/2;
  justify-self: end;
  align-self: center;
  width: 15vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: white;
}

.SocialIcons i {
  color: white;
}

.Transparentlogo {
  width: 10vw;
  grid-row: 1/2;
  grid-column: 2/3;
  justify-self: start;
  align-self: center;
}

.Copyright {
  grid-row: 2/3;
  grid-column: 1/3;
  justify-self: center;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
}

@media only screen and (max-width: 830px) {

  .Container {
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .Page {
    display: grid;
    height: 100vh;
    grid-template-rows: 7% 1fr 1fr 15%;
    grid-template-columns: 0px 1fr 1fr 0px;
    grid-template-areas:
            "nav nav nav nav"
            ". head head ."
            ". desc desc ."
            ". thanks thanks .";
    padding: 0px;
    margin: 0px;
  }

  .Logo {
    width: 20vw;
    padding: 0%;
    margin: 20px;
    position: fixed;
  }

  .Self {
    grid-area: head;
    width: 50%;
    padding: 0px;
    margin: 0px;
    place-self: center;
  }

  .Title {
    font-size: 8vw;
    color: $myBlack;
    padding: 0px;
    margin: 0px;
  }

  .Subtitle {
    font-size: 4vw;
    color: #D26463;
    padding: 0px;
    margin: 0px;
  }

  .Description {
    font-size: 3vw;
    color: $myBlackSecondary;
    padding: 0px;
    margin: 10px;
  }

  .Thanks-For {
    grid-area: thanks;
    animation: spin 8s infinite linear;
    place-self: center;
    width: 40%;
    object-fit: cover;
    padding: 0px;
    margin: 0px;
  }

  .Big-Lukas {
    display: none;
  }

  .Big-Viskanta {
    display: none;
  }

  .Content {
    display: grid;
    grid-template-rows: 2.5% 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 5% 1fr 5%;
    height: 350vh;
    width: 95vw;
    margin-top: 20vh;


  }

  #pf {
    height: 275vh;
    margin-top: 5vh;
  }

  .Item1 {
    grid-row: 2/3;
    grid-column: 2/3;
    justify-self: center;
    background-color: gray;
  }

  .Item2 {
    grid-row: 1/2;
    grid-column: 2/3;
    place-self: center;
    display: none;
  }

  .Item3 {
    grid-row: 1/2;
    grid-column: 3/4;
    place-self: center;
    display: none;
  }

  .Item4 {
    grid-row: 1/2;
    grid-column: 4/5;
    place-self: center;
    display: none;
  }

  .Item5 {
    grid-row: 3/4;
    grid-column: 2/3;
    place-self: center;
  }

  .Item6 {
    grid-row: 1/2;
    grid-column: 6/7;
    place-self: center;
    display: none;
  }

  .Item7 {
    grid-row: 4/5;
    grid-column: 2/3;
    place-self: center;

  }

  .Item8 {
    grid-row: 2/3;
    grid-column: 2/3;
    place-self: center;
    display: none;
  }

  .DesignTitle {
    grid-row: 1/2;
    grid-column: 2/3;
    font-family: futura-pt-bold, sans-serif;
    font-size: 17vw;

  }

  .Item9 {
    grid-row: 5/6;
    grid-column: 2/3;
    place-self: center;
  }

  .Item10 {
    grid-row: 6/7;
    grid-column: 2/3;
    place-self: center;
  }

  .Item11 {
    grid-row: 3/4;
    grid-column: 1/2;
    place-self: center;
    display: none;
  }

  .Item12 {
    grid-row: 7/8;
    grid-column: 2/3;
    place-self: center;
  }

  .Item13 {
    grid-row: 3/4;
    grid-column: 3/4;
    place-self: center;
    display: none;
  }

  .Item14 {
    grid-row: 3/4;
    grid-column: 4/5;
    place-self: center;
    display: none;
  }

  .Item15 {
    grid-row: 3/4;
    grid-column: 5/6;
    place-self: center;
    display: none;
  }

  .Item16 {
    grid-row: 3/4;
    grid-column: 6/7;
    place-self: center;
    display: none;
  }

  .UILayout {
    display: none;
  }

  .Content2 {
    display: grid;
    grid-template-rows: 2.5% 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 5% 1fr 5%;
    height: 350vh;
    width: 95vw;
    margin-top: 20vh;
    margin-bottom: 0vh;
  }

  .UITitle {
    display: block;
    grid-row: 1/2;
    grid-column: 1/4;
    font-family: futura-pt-bold, sans-serif;
    font-size: 18vw;
    text-align: center;
    place-self: center;
  }

  .ele1 {
    display: block;
    grid-row: 2/3;
    grid-column: 2/3;
    place-self: center;
  }

  .ele2 {
    display: block;
    grid-row: 2/3;
    grid-column: 2/3;
    place-self: center;
  }

  .ele3 {
    display: block;
    grid-row: 3/4;
    grid-column: 2/3;
    place-self: center;

  }

  .ele4 {
    display: block;
    grid-row: 4/5;
    grid-column: 2/3;
    place-self: center;

  }

  .ele5 {
    display: block;
    grid-row: 5/6;
    grid-column: 2/3;
    place-self: center;

  }

  .ele6 {
    display: block;
    grid-row: 6/7;
    grid-column: 2/3;
    place-self: center;

  }

  .Footer {
    width: 100vw;
    background-color: #3F7EBF;
    color: white;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    padding: 15px;
  }

  .SocialIcons{
    grid-row: 1/2;
    grid-column: 1/2;
    justify-self: end;
    align-self: center;
    width: 25vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: white;
  }

  .SocialIcons i {
    color: white;
  }

  .Transparentlogo {
    width: 20vw;
    grid-row: 1/2;
    grid-column: 2/3;
    justify-self: start;
    align-self: center;
  }

  .Copyright {
    grid-row: 2/3;
    grid-column: 1/3;
    justify-self: center;
    font-family: futura-pt, sans-serif;
    font-weight: 500;
  }

}





